<template>
    <div id="app" class="px-2">
        <div id="header">
            <nav class="navbar navbar-expand-sm mb-2">
                <!-- Brand/logo -->
                <a class="navbar-brand" href="/home">
                    <img
                        alt="Vitamine Locale"
                        src="./assets/vitamine_locale.svg"
                        class="logo"
                    />

                    <svg-icon icon-class="vitamine_local"></svg-icon>
                </a>

                <nav v-if="$store.getters.signedIn" class="d-flex"></nav>

                <!-- navbar right -->
                <ul class="nav ml-auto">
                    <template v-if="$store.getters.signedIn">
                        <router-link
                            :to="{ name: 'ProductsPage' }"
                            class="nav-link"
                            ><i class="fas fa-carrot" title="Produits"></i
                        ></router-link>
                        <router-link
                            :to="{ name: 'CustomersPage' }"
                            class="nav-link"
                            ><i class="fas fa-user-friends" title="Clients"></i
                        ></router-link>
                        <router-link
                            :to="{ name: 'ExportPage' }"
                            class="nav-link"
                            ><i class="fas fa-file-download" title="Export"></i
                        ></router-link>
                        <router-link
                            :to="{ name: 'DouzaineProduct' }"
                            class="nav-link"
                            ><i class="fas fa-shopping-basket" title="Plan Douzaines"></i
                        ></router-link>

                        <li class="nav-item">
                            <logout-button link_class="nav-link" />
                        </li>
                    </template>
                    <template v-else>
                        <router-link to="/login" class="nav-link"
                            >Connexion</router-link
                        >
                    </template>
                </ul>
            </nav>
        </div>

        <the-single></the-single>

        <div class="container-fluid" id="content-wrapper">
            <router-view />
        </div>
        <div id="footer"></div>
        <flash class="alert alert-flash"></flash>
    </div>
</template>
<script>

export default {
  name: 'App',
  components: {

  },
  created() {
    /**
     * Handling the unauthorized case scenario
     * https://blog.sqreen.com/authentication-best-practices-vue/
     */    
    axios.interceptors.response.use(undefined, function (err){
      return new Promise(function (resolve, reject) {
        if (err.response.status === 401 ) {
          console.log('401 in app.vue');
          localStorage.removeItem("token")
          window.location.href = "/login";
          // you can also redirect to /login if needed !
        }
        throw err;
      });
    });
  }
}
</script>
<style lang="scss">
</style>
