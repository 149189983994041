export default {
  data() {
    return {
      customer: {},
      order_products: [],
      loaded: false,
      products: [],
      order_product_edit: null, // for distributed_quantity_modal
      distributed_quantity: null, // for distributed_quantity_modal
      edit: false, // edit permission
    };
  },
  methods: {
    get_class(ready) {
      return ready ? "text-success" : "text-secondary";
    },

    toggle_ready(order_product) {
      if (!this.edit) {
        flash("Vous n'avez pas le droit de modifier", "warning");
        return;
      }
      order_product.ready = !order_product.ready;

      let url;
      const id = order_product.id;
      const state = order_product.current_state; 
      if (state == 3) {
        url = "/order-product/" + id;
      }
      if (state == 14) {
        url = "/product-associated/" + id;
      }
      axios
        .patch(url, {
          ready: order_product.ready,
        })
        .then(({ data }) => {
          flash("modifié avec succès!");
        });

    },

    toggle_ready_d(order) {
      if (!this.edit) {
        flash("Vous n'avez pas le droit de modifier", "warning");
        return;
      }
      order.ready = !order.ready;
      axios
        .patch("/douzaine-date/" + order.date_id, {
          ready: order.ready,
        })
        .then(({ data }) => {
          if (data.message) flash(data.message, danger);
          flash("modifié avec succès!");
        }).catch((error) => {
          console.log(error);
          if (error.response) flash(error.response.data, "danger");
        });
    },

    show_distributed_quantity_modal(order_product) {
      if (!this.edit) {
        flash("Vous n'avez pas le droit de modifier", "warning");
        return;
      }
      this.order_product_edit = order_product;
      this.distributed_quantity = order_product.distributed_quantity;
      this.$modal.show("distributed_quantity_modal");
    },

    update_disctributed_quantity() {
      const value = this.distributed_quantity;
      const id = this.order_product_edit.id;
      const state = this.order_product_edit.current_state;
      // epicerie
      let url;
      if (state == 3) {
        url = "/order-product/" + id;
      }
      if (state == 14) {
        url = "/product-associated/" + id;
      }
      axios
        .patch(url, {
          distributed_quantity: value,
        })
        .then(({ data }) => {
          // data is $order_product;
          console.log(data);
          let order_product = this.order_products.find(
            (elem) => elem.current_state == state && elem.id == id
          );
          order_product.distributed_quantity =
            data.distributed_quantity;
          order_product.ready = data.ready;
          this.$modal.hide("distributed_quantity_modal");
          flash("modifié avec succès!");
        })
        .catch((error) => {
          console.log(error);
          if (error.response) flash(error.response.data, "danger");
        });
    },

  }
}